import React from "react";
// react context api HOC component (propagate datas to components using context)
import { WithParcellesActives } from "parcelles/provider";
// leaflet
import L from "leaflet";
import {
  MapContainer as LeafletMap,
  TileLayer,
  GeoJSON,
  FeatureGroup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
// react-leaflet-draw
import { EditControl } from "react-leaflet-draw";
// react-leaflet gps location
import Locate from "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
// leaflet screenshoter
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
// template utilisé dans le modal lors du clique sur une parcelle
import ModalParcelleTemplate from "./ModalParcelleTemplate";
// couleurs des cultures
import { couleursCultures } from "parcellaire/couleurs.js";
// react-bootstrap components
import { Badge, Modal, Button } from "react-bootstrap";

class MapDraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - (props.reduceHeight ? 250 : 150),
      isLegendeVisible: true,
      isDraw:
        props.parcelle && props.parcelle[0].geometry.coordinates ? true : false,
      isParcelleDisplayed: false,
      modal: {
        isVisible: false,
        parcelle: {
          id: undefined,
          label: undefined,
          surface: undefined,
          typeCulture: undefined,
          culture: undefined,
          varietes: undefined,
        },
      },
    };
    this.mapRef = React.createRef();
    this.featureGroup = undefined;
    this.screenshotter = undefined;
  }

  componentDidMount() {
    this.updateDimensions();
    // window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  onMapReady(mapInstance) {
    // attacher l'instance de la map à la classe
    if (!this.mapRef.current) {
      this.mapRef.current = mapInstance.target;
    }
    // leaflet screenshoter
    if (!this.screenshotter) {
      this.screenshotter = new SimpleMapScreenshoter();
      this.screenshotter.addTo(mapInstance.target);
    }
    // leaflet geolocation
    if (this.props.isGeolocation) {
      // géolocalisation - point GPS
      const lc = new Locate({
        position: "topright",
        // returnToPrevBounds: true,
        setView: false,
        showPopup: false,
        strings: {
          title: "Me localiser",
        },
        locateOptions: {
          enableHighAccuracy: true,
        },
        // onActivate: () => {} // callback before engine starts retrieving locations
      });
      // afficher l'icône de geolocalisation sur la carte
      lc.addTo(mapInstance.target);
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateDimensions.bind(this));
  // }

  updateDimensions() {
    // const height = window.innerWidth >= 992 ? window.innerHeight : 400;
    // const difference = this.props.fullHeight ? 150 : 400;
    // const height = window.innerHeight - difference;
    const height = window.innerHeight * 0.6;
    this.setState({ height: height });
  }

  updateUI = () => {
    setTimeout(() => {
      if (this.mapRef.current) {
        this.mapRef.current.invalidateSize(false);
        const bounds = this.getBounds();
        this.mapRef.current.fitBounds(bounds);
      }
      // TODO : il faut peut-être augmenter la durée ?
    }, 0);
  };

  toggleLegende = () => {
    this.setState({ isLegendeVisible: !this.state.isLegendeVisible });
  };

  editParcelle = () => {
    this.props.history.push("/createParcelle", {
      id: this.state.modal.parcelle.id,
    });
  };

  createIntervention = () => {
    // requete parametrable
    this.props.history.push(
      "/parametrable?requete=boutons&ecran=interventions",
      {
        parcelles: [this.state.modal.parcelle],
      }
    );
  };

  openInterventions = () => {
    this.props.history.push("interventions", {
      activeFilters: { parcelles: [this.state.modal.parcelle] },
    });
  };

  hideModal = () => {
    this.setState((state) => {
      state.modal.isVisible = false;
      return state;
    });
  };

  // parcelles à afficher
  getParcelles = () => {
    let parcelles;
    // si parcelles définies dans les props
    if (this.props.parcelles) {
      // utiliser ces parcelles
      parcelles = this.props.parcelles;
    } else {
      // parcelles du context
      parcelles = this.props.ctx.parcelles;
      // si contours parcelle editable
      if (this.props.isParcelleEditable) {
        // retirer cette parcelle du geojson (deja présente dans le feature group)
        parcelles = parcelles.filter(
          (p) => p.properties.id !== this.props.parcelle[0].properties.id
        );
      }
    }
    // retirer les parcelles qui n'ont pas de contours (éviter le crash)
    return parcelles.filter(
      (p) =>
        p.geometry && p.geometry.coordinates && p.geometry.coordinates.length
      // p.geometry.type &&
      // p.geometry.type !== "Point"
    );
  };

  // centrage de la map
  getBounds = () => {
    let bounds = L.geoJson([
      [51.083333, -5.133333],
      [41.333333, 9.533333],
    ]).getBounds();

    if (
      // éviter le crash si nouvelle parcelle (pas de coordinates)
      this.props.bounds &&
      this.props.bounds[0].geometry.coordinates &&
      this.props.bounds[0].geometry.coordinates.length &&
      // éviter le crash si cercle (coordinates qui ne permettent pas d'avoir des bounds)
      this.props.bounds[0].geometry.type !== "Point"
    ) {
      bounds = L.geoJson(this.props.bounds).getBounds();
    } else {
      bounds = L.geoJson(this.getParcelles()).getBounds();
    }

    if (!bounds.isValid()) {
      bounds = [
        [51.083333, -5.133333],
        [41.333333, 9.533333],
      ];
    }
    return bounds;
  };

  onFeatureGroupReady = (featureGroup) => {
    // attacher le featureGroup à la classe
    if (!this.featureGroup) {
      this.featureGroup = featureGroup;
    }

    // si contour parcelle modifiable
    if (this.props.isParcelleEditable) {
      // si parcelle pas encore affichée
      if (
        featureGroup &&
        !this.state.isParcelleDisplayed &&
        this.props.parcelle[0].geometry.coordinates
      ) {
        // ajouter la parcelle dans le featureGroup (pour pouvoir l'éditer)
        let leafletGeoJSON = new L.GeoJSON(this.props.parcelle);
        leafletGeoJSON.eachLayer((layer) => {
          featureGroup.addLayer(layer);
        });
        this.setState({ isParcelleDisplayed: true });
      }

      if (featureGroup) {
        const couleurCulture = this.getCouleurCulture(
          this.props.culture[0] ? this.props.culture[0].id : undefined
        );
        // mettre la couleur de la culture
        featureGroup.eachLayer((layer) => {
          if (layer.setStyle) {
            layer.setStyle({
              color: couleurCulture,
              fillColor: couleurCulture,
            });
          }
        });
      }
    }
  };

  onEachFeature(feature, layer) {
    if (this.props.isModal) {
      this.showModal(feature, layer);
    } else if (this.props.isClickable) {
      this.enableClick(feature, layer);
    }
  }

  showModal(feature, layer) {
    layer.on({
      click: () => {
        this.setState({
          modal: {
            isVisible: true,
            parcelle: {
              id: layer.feature.properties.id,
              label: layer.feature.properties.label,
              surface: layer.feature.properties.surface,
              typeCulture: layer.feature.properties.typeCulture,
              culture: layer.feature.properties.culture,
              varietes: layer.feature.properties.varietes,
            },
          },
        });
      },
    });
  }

  enableClick(feature, layer) {
    layer.on({
      click: () => {
        const toggledParcelle = {
          id: layer.feature.properties.id,
          label: layer.feature.properties.label,
          surface: +Number(layer.feature.properties.surface).toFixed(2),
          culture: layer.feature.properties.culture,
        };
        // si fonction demandée par le parent
        if (this.props.handleClick) {
          this.props.handleClick(toggledParcelle.id);
        } else {
          this.props.ctx.toggle(toggledParcelle);
        }
      },
    });
  }

  getCouleurCulture = (cultureId) => {
    const correspondance = couleursCultures.find((item) =>
      item.culturesIds.includes(cultureId)
    );
    if (correspondance) {
      return correspondance.couleur;
    } else {
      return "#B0BEC5";
    }
  };

  defaultStyle = (layer) => {
    const cultureId = layer.properties.culture[0].id;
    const couleurCulture = this.getCouleurCulture(cultureId);

    return {
      color: couleurCulture,
      fillColor: couleurCulture,
    };
  };

  dynamicStyle = (layer) => {
    const parcelleId = layer.properties.id;
    const isSelected = this.props.ctx.isActive(parcelleId);
    const cultureId = layer.properties.culture[0].id;
    const couleurCulture = this.getCouleurCulture(cultureId);

    if (isSelected) {
      return {
        // valeurs leaflet par défaut
        // fillOpacity: 0.2,
        // weight: 3,

        // correspondance couleurs success
        // success bg "#c3e6cb",
        // success text "#155724"

        color: couleurCulture,
        fillOpacity: 0.9,
        fillColor: couleurCulture,
      };
    } else {
      return {
        color: couleurCulture,
        fillOpacity: 0.2,
        fillColor: couleurCulture,
      };
    }
  };

  getLayerSurface(layerType, layer) {
    let surface_ha;
    if (layerType === "polygon") {
      const surface_m2 = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
      surface_ha = Number(surface_m2 / 10000).toFixed(2);
    } else if (layerType === "circle") {
      const rayon_m = layer.getRadius();
      surface_ha = Number((Math.PI * Math.pow(rayon_m, 2)) / 10000).toFixed(2);
    }
    return Number(surface_ha);
  }

  onDrawCreated = (e) => {
    const feature = e.layer.toGeoJSON();
    const surface_ha = this.getLayerSurface(e.layerType, e.layer);
    this.props.setGeometry({
      geometry: feature.geometry,
      surface: surface_ha,
    });
    this.setState({ isDraw: true });
  };

  onDrawEdited = (e) => {
    const layers = this.featureGroup.getLayers();
    let editedLayer;
    // bug leaflet : 2 layers sont créés lors de la modification !?
    const layersLength = layers.length;
    // si > 1 layer
    if (layersLength > 1) {
      // supprimer le 1er layer
      this.featureGroup.removeLayer(layers[0]);
      // utiliser le 2eme layer
      editedLayer = layers[1];
    } else {
      // utiliser le 1er layer
      editedLayer = layers[0];
    }

    if (editedLayer) {
      const feature = editedLayer.toGeoJSON();
      let layerType;
      if (editedLayer instanceof L.Polygon) {
        layerType = "polygon";
      } else if (editedLayer instanceof L.Circle) {
        layerType = "circle";
      }
      const surface_ha = this.getLayerSurface(layerType, editedLayer);
      this.props.setGeometry({
        geometry: feature.geometry,
        surface: surface_ha,
      });
    }
  };

  onDrawDeleted = () => {
    this.props.setGeometry({
      geometry: { type: "Polygon", coordinates: [] },
      surface: 0,
    });
    this.setState({ isDraw: false });
  };

  getScreenshot = async () => {
    const screenshotter = this.screenshotter;
    const featureBounds = this.getBounds().pad(0.1);
    // Get pixel position on screen of top left and bottom right
    // of the bounds of the feature
    const nw = featureBounds.getNorthWest();
    const se = featureBounds.getSouthEast();
    const topLeft = this.mapRef.current.latLngToContainerPoint(nw);
    const bottomRight = this.mapRef.current.latLngToContainerPoint(se);
    // Get the resulting image size that contains the feature
    const imageSize = bottomRight.subtract(topLeft);
    return new Promise(function (resolve, reject) {
      screenshotter
        .takeScreen("image")
        .then((image) => {
          var img = new Image();
          img.onload = () => {
            // Create canvas to process image data
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // Set canvas size to the size of your resultant image
            canvas.width = imageSize.x;
            canvas.height = imageSize.y;
            // Draw just the portion of the whole map image that contains
            // your feature to the canvas
            // from https://stackoverflow.com/questions/26015497/how-to-resize-then-crop-an-image-with-canvas
            // ctx.drawImage(img, 0, 0);
            ctx.drawImage(
              img,
              topLeft.x,
              topLeft.y,
              imageSize.x,
              imageSize.y,
              0,
              0,
              imageSize.x,
              imageSize.y
            );
            var imageurl = canvas.toDataURL("image/png");
            resolve(imageurl);
          };
          img.src = image;
        })
        .catch((e) => {
          console.error(e);
          reject();
        });
    });
  };

  getLegendDom = () => {
    return document.querySelector("#legend");
  };

  render() {
    const bounds = this.getBounds();
    const draws = this.props.draws;
    const isPolygon = draws && draws.includes("polygon");
    const isCircle = draws && draws.includes("circle");
    const isPolyline = draws && draws.includes("polyline");
    const isDraw = this.state.isDraw;
    const isLegende = this.props.isLegende;
    const { isLegendeVisible, isParcelleDisplayed } = this.state;
    const couleurCulture = this.getCouleurCulture(
      this.props.culture && this.props.culture[0]
        ? this.props.culture[0].id
        : undefined
    );
    return (
      <div style={{ width: "100%", height: this.state.height }}>
        <LeafletMap
          bounds={bounds}
          dragging
          scrollWheelZoom
          doubleClickZoom
          style={{ width: "100%", height: "100%" }}
          whenReady={this.onMapReady.bind(this)}
        >
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <GeoJSON
            key={-1}
            data={this.getParcelles()}
            onEachFeature={
              this.props.isClickable || this.props.isModal
                ? this.onEachFeature.bind(this)
                : undefined
            }
            style={(layer) =>
              this.props.isClickable
                ? this.dynamicStyle(layer)
                : this.defaultStyle(layer)
            }
          />
          {this.props.draws && (
            <FeatureGroup
              ref={(featureGroup) => {
                this.onFeatureGroupReady(featureGroup);
              }}
            >
              <EditControl
                position="topright"
                onCreated={this.onDrawCreated}
                onEdited={this.onDrawEdited}
                onDeleted={this.onDrawDeleted}
                draw={{
                  polyline: isPolyline
                    ? {
                        shapeOptions: {
                          color: "#000000",
                          fillColor: "#000000",
                        },
                      }
                    : false,
                  rectangle: false,
                  marker: false,
                  circlemarker: false,
                  polygon:
                    isPolygon && !isDraw
                      ? {
                          allowIntersection: false,
                          showArea: true,
                          showLength: true,
                          metric: ["ha"],
                          shapeOptions: {
                            color: couleurCulture,
                            fillColor: couleurCulture,
                          },
                        }
                      : false,
                  circle:
                    isCircle && !isDraw
                      ? {
                          shapeOptions: {
                            color: couleurCulture,
                            fillColor: couleurCulture,
                          },
                        }
                      : false,
                }}
              />
            </FeatureGroup>
          )}
        </LeafletMap>
        {isParcelleDisplayed && (
          <Button
            variant="success"
            onClick={() => this.updateUI()}
            className="mt-3"
          >
            Zoomer sur la parcelle
          </Button>
        )}
        {isLegende && (
          <div id="legend" className="d-flex flex-wrap mt-2">
            <Badge
              variant={isLegendeVisible ? "success" : "danger"}
              className="mr-1 mb-1"
              onClick={this.toggleLegende}
            >
              {isLegendeVisible ? "Légende visible" : "Légende cachée"}
            </Badge>
            {isLegendeVisible &&
              couleursCultures.map((culture, index) => (
                <Badge
                  key={index}
                  pill
                  variant="dark"
                  style={{ backgroundColor: culture.couleur }}
                  className="mr-1 mb-1"
                >
                  {culture.label}
                </Badge>
              ))}
          </div>
        )}
        <Modal show={this.state.modal.isVisible} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <ModalParcelleTemplate parcelle={this.state.modal.parcelle} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              {this.props.isParcellaire && (
                <Button
                  variant="warning"
                  className="mb-3"
                  onClick={this.editParcelle}
                >
                  Modifier parcelle
                </Button>
              )}
              <Button
                variant="warning"
                className="mb-3"
                onClick={this.createIntervention}
              >
                Nouvelle intervention
              </Button>
              <Button variant="warning" onClick={this.openInterventions}>
                Historique intervention
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default WithParcellesActives(MapDraw);

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
//   iconUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
//   shadowUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png"
// });

// Leaflet draw toolbar French translation
L.drawLocal.draw.toolbar.finish.text = "Valider";
L.drawLocal.draw.toolbar.actions.text = "Annuler";
L.drawLocal.draw.toolbar.undo.text = "supprimer dernier point";
L.drawLocal.draw.toolbar.buttons.polygon = "Dessiner une nouvelle parcelle";
L.drawLocal.draw.toolbar.buttons.circle = "Dessiner une nouvelle parcelle";
L.drawLocal.draw.toolbar.buttons.polyline = "Dessiner une ligne de découpe";
L.drawLocal.draw.handlers.polygon.tooltip.start =
  "Cliquez pour commencer à dessiner la parcelle";
L.drawLocal.draw.handlers.polygon.tooltip.cont =
  "Cliquez pour continuer à dessiner la parcelle";
L.drawLocal.draw.handlers.polygon.tooltip.end =
  "Cliquez sur le 1er point pour refermer la parcelle";
L.drawLocal.draw.handlers.circle.tooltip.start =
  "Cliquez au centre et étirez pour dessiner un cercle";
L.drawLocal.draw.handlers.circle.radius = "Rayon";
L.drawLocal.edit.toolbar.actions.save.text = "Valider";
L.drawLocal.edit.toolbar.actions.cancel.text = "Annuler";
L.drawLocal.edit.toolbar.actions.clearAll.text = "Tout supprimer";
L.drawLocal.edit.toolbar.buttons.edit = "Modifier la parcelle";
L.drawLocal.edit.toolbar.buttons.editDisabled = "Aucune parcelle à modifier";
L.drawLocal.edit.toolbar.buttons.remove = "Supprimer la parcelle";
L.drawLocal.edit.toolbar.buttons.removeDisabled = "Aucune parcelle à supprimer";
L.drawLocal.edit.handlers.edit.tooltip.text =
  "Déplacez les points pour modifier les contours de la parcelle";
L.drawLocal.edit.handlers.edit.tooltip.subtext =
  "Cliquez sur annuler pour revenir en arrière";
L.drawLocal.edit.handlers.remove.tooltip.text =
  "Cliquez sur la parcelle pour la supprimer";
