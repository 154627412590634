import React from "react";
// react-bootstrap components
import { Row, Col, Card, Form } from "react-bootstrap";

const General = ({ mouvement, handleChange, isUpdate }) => {
  return (
    <Card.Body>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Date
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="date"
              type="date"
              value={mouvement.date}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>
        {
          <fieldset>
            <Form.Group as={Row}>
              <Form.Label as="legend" column sm={4}>
                Type de mouvement
              </Form.Label>
              <Col sm={4}>
                <Form.Check
                  type="radio"
                  label="Entrée"
                  name="typeDeMouvementId"
                  value={1}
                  checked={mouvement.typeDeMouvementId === 1}
                  onChange={handleChange}
                  disabled={isUpdate}
                />
                <Form.Check
                  type="radio"
                  label="Sortie"
                  name="typeDeMouvementId"
                  value={2}
                  checked={mouvement.typeDeMouvementId === 2}
                  onChange={handleChange}
                  disabled={isUpdate}
                />
              </Col>
            </Form.Group>
          </fieldset>
        }
      </Form>
    </Card.Body>
  );
};

export default General;
