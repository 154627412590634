const couleursCategories = {
  1: "#9C27B0", // ENGRAIS
  2: "#4CAF50", // HERBICIDE
  3: "#F44336", // FONGICIDE
  6: "#F44336", // TRAITEMENT DE SEMENCE
  11: "#3F51B5", // OLIGO-BIOSTIMULANTS
  12: "#795548", // ADJUVANT
  13: "#FFC107", // INSECTICIDE
  14: "#03A9F4", // MOLLUSCICIDE
  20: "#9E9E9E", // REGULATEUR
  22: "#795548" // AMENDEMENT ORGANIQUE
};

export const getCategories = (array, interventionTypeId) => {
  // deep copy du tableau car plusieurs niveaux avec options (sinon on modifie le tableau d'origine)
  let categories = JSON.parse(JSON.stringify(array));

  // interventionTypeId = undefined pour les mouvements de stock
  if (interventionTypeId) {
    // Récupérer uniquement les catégories correspondantes au type d'intervention sélectionné
    categories = categories.filter(categorie => {
      return categorie.typeIds.some(type => type.id === interventionTypeId);
    });
  }

  categories.forEach(categorie => {
    // Définir l'ordre d'affichage de la catégorie (index)
    categorie.index = getPosition(categorie.id, interventionTypeId);
    // supprimer les produits de la catégorie (sinon elles s'affichent dans la liste déroulante)
    delete categorie.options;
    if (couleursCategories[categorie.id]) {
      categorie.color = couleursCategories[categorie.id];
    }
  });

  // Rajouter la catégorie "Toutes" en première position
  categories.unshift({
    id: -1, // id: 0 déjà utilisé
    label: "Toutes les catégories",
    index: -1
  });

  // Trier les catégories
  sortByIndexAndAlpha(categories);

  return categories;
};

export const getCategorie = (
  array,
  interventionTypeId,
  categorieId,
  isMultiExploitations,
  stockPrestataire
) => {
  // deep copy du tableau car plusieurs niveaux avec options (sinon on modifie le tableau d'origine)
  let categories = JSON.parse(JSON.stringify(array));

  // interventionTypeId = undefined pour les mouvements de stock
  if (interventionTypeId) {
    // Récupérer uniquement les catégories correspondantes au type d'intervention sélectionné
    categories = categories.filter(categorie => {
      return categorie.typeIds.some(type => type.id === interventionTypeId);
    });
  }

  let categorie = [];
  if (categorieId !== -1) {
    // récupérer la catégorie grâce à son id
    categorie = categories.filter(cat => cat.id === categorieId);
  } else {
    // catégorie "Toutes"
    categorie = categories;
  }

  categorie.forEach(cat => {
    // Définir l'ordre d'affichage de la catégorie
    cat.index = getPosition(cat.id, interventionTypeId);
    // Définir les propriétés des produits
    cat.options.forEach(option => {
      // tous les produits ont un champ quantité
      option.quantite = "";
      // tous les produits qui n'appartiennent pas à la catégorie "traitement de semence" ont un champ quantité surfacique
      if (cat.id !== 6) {
        option.quantitePerSurface = "";
      }
      // les produits de type variété ont un champ "type de semence" et "pmg"
      if (option.variete) {
        option.typeSemence = 1;
        option.pmg = "";
      }
      // nouveau fonctionnement : nbGrainesParDose renvoyée par le serveur
      if (option.dose) {
        option.quantiteGrainesPerSurface = "";
        option.nbGrainesParDose = option.dose;
      }
      if (
        typeof isMultiExploitations === "boolean" &&
        typeof stockPrestataire === "boolean"
      ) {
        if (isMultiExploitations) {
          option.origineStock = stockPrestataire ? 1 : 2
        } else {
          option.origineStock = 2
        }
      }
      const couleurCategorie = couleursCategories[cat.id];
      if (couleurCategorie) {
        option.color = couleurCategorie;
      }
    });
  });

  // Trier la catégorie
  sortByIndexAndAlpha(categorie);

  return categorie;
};

export const getProduits = array => {
  // deep copy du tableau car plusieurs niveaux avec options (sinon on modifie le tableau d'origine)
  let categories = JSON.parse(JSON.stringify(array));

  let produits = [];

  // récupérer tous les produits sans les catégories
  categories.forEach(categorie => {
    categorie.options.forEach(option => {
      produits.push(option);
    });
  });

  return produits;
};

// Fonction qui renvoi un index en fonction de la catégorie
// l'index est utilisé pour définir l'ordre de la catégorie dans la liste
const getPosition = (categoryId, interventionTypeId) => {
  let index = 999;
  // SEMIS
  if (interventionTypeId === 76) {
    // TRAITEMENT DE SEMENCE
    if (categoryId === 6) {
      index = 1;
      // INSECTICIDES
    } else if (categoryId === 13) {
      index = 2;
      // MOLLUSCICIDE
    } else if (categoryId === 14) {
      index = 3;
      // OLIGO-BIOSTIMULANTS
    } else if (categoryId === 11) {
      index = 4;
      // ENGRAIS
    } else if (categoryId === 1) {
      index = 5;
      // AMENDEMENT ORGANIQUE
    } else if (categoryId === 22) {
      index = 6;
    }
    // TRAITEMENT PHYTO
  } else if (interventionTypeId === 60) {
    // HERBICIDE
    if (categoryId === 2) {
      index = 1;
      // FONGICIDE
    } else if (categoryId === 3) {
      index = 2;
      // INSECTICIDE
    } else if (categoryId === 13) {
      index = 3;
      // REGULATEUR
    } else if (categoryId === 20) {
      index = 4;
      // MOLLUSCICIDE
    } else if (categoryId === 14) {
      index = 5;
      // ADJUVANT
    } else if (categoryId === 12) {
      index = 6;
      // OLIGO-BIOSTIMULANTS
    } else if (categoryId === 11) {
      index = 7;
    }
  } else {
    // ADJUVANT
    if (categoryId === 12) {
      index = 1;
      // AMENDEMENT ORGANIQUE
    } else if (categoryId === 22) {
      index = 2;
      // ENGRAIS
    } else if (categoryId === 1) {
      index = 3;
      // FONGICIDE
    } else if (categoryId === 3) {
      index = 4;
      // HERBICIDE
    } else if (categoryId === 2) {
      index = 5;
      // INSECTICIDE
    } else if (categoryId === 13) {
      index = 6;
      // MOLLUSCICIDE
    } else if (categoryId === 14) {
      index = 7;
      // OLIGO-BIOSTIMULANTS
    } else if (categoryId === 11) {
      index = 8;
      // TRAITEMENT DE SEMENCE
    } else if (categoryId === 6) {
      index = 9;
    }
  }
  return index;
};

// Fonction qui trie un tableau d'objets en fonction :
// 1 - de l'index
// 2 - du label (ordre alphabétique)
const sortByIndexAndAlpha = array => {
  // eslint-disable-next-line array-callback-return
  array.sort((a, b) => {
    // sort by index first
    if (a.index > b.index) return 1;
    if (b.index > a.index) return -1;
    // sort by alpha secondly
    if (a.label > b.label) return 1;
    if (b.label > a.label) return -1;
  });
};

// Fonction qui rajoute les quantités par défaut
export const addQuantity = options => {
  let optionsWithQuantity = [];
  options.forEach(function(option) {
    if (!option.options) {
      optionsWithQuantity.push({
        ...option,
        quantite: 0
      });
    } else {
      optionsWithQuantity.push({
        label: option.label,
        options: option.options.map(option => {
          return { ...option, quantite: 0 };
        })
      });
    }
  });
  return optionsWithQuantity;
};

export const listeTypesIntervention = [
  {
    id: 75,
    label: "Travail du sol",
    // icon: "GiPlow"
    icon: "GiFarmTractor"
  },
  {
    id: 76,
    label: "Semis",
    // icon: "GiSeedling"
    icon: "GiGroundSprout"
  },
  {
    id: 61,
    label: "Fertilisation / Amendement",
    icon: "GiFertilizerBag"
  },
  {
    id: 60,
    label: "Traitement phytosanitaire",
    // icon: "GiChemicalDrop"
    // icon: "GiSpray"
    icon: "../../assets/buse.svg"
  },
  {
    id: 90,
    label: "Façon culturale post-semis",
    icon: "GiFarmer"
  },
  {
    id: 63,
    label: "Irrigation",
    // icon: "GiWaterDrop"
    icon: "GiTap"
  },
  {
    id: 82,
    label: "Récolte",
    // icon: "GiCorn"
    icon: "../../assets/moissonneuse.svg"
  },
  {
    id: 92,
    label: "Transport",
    icon: "GiRoad"
  },
  {
    id: 91,
    label: "Observation",
    icon: "GiBinoculars"
    // icon: "GiSunglasses"
    // icon: "GiMagnifyingGlass"
    // icon: "GiSpyglass"
  },
  {
    id: 1000115,
    label: "Autres produits",
    icon: "GiMoneyStack"
  },
  {
    id: 1000116,
    label: "Autres charges",
    icon: "GiPiggyBank"
  }
];

export const listeStatuts = [
  {
    value: 1,
    label: "Prévu",
    color: "danger"
  },
  {
    value: 2,
    label: "Planifié",
    color: "warning"
  },
  {
    value: 3,
    label: "Effectué",
    color: "success"
  }
];
