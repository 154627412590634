import React from "react";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import { Container, Row, Col, Button } from "react-bootstrap/";
// ag-grid component
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AllModules } from "ag-grid-enterprise";
// react-icons
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
// custom style
import "./grid.css";
// ag-grid base configuration
import { baseConfig } from "../ag-grid/config";
// momentjs
import moment from "moment";
// brand logo
// import logo from "../logo.png";
import logoPPf from "../logo_ppf.png";

class Grid extends React.Component {
  constructor(props) {
    super();
    this.state = {
      width: "100%",
      resized: false,
    };
    this.pathname =
      props.history.location.pathname + props.history.location.search;
    if (props.specificConfig.title) {
      this.pathname += ` - ${props.specificConfig.title}`;
    }
    this.containerRef = React.createRef();
  }

  setGridFilters = () => {
    const historyState = this.props.history.state;
    // si filtres précédemment activés par l'utilisateur
    if (
      historyState &&
      historyState[this.pathname] &&
      this.props.history.location.pathname !== "/parcellaire"
    ) {
      // utiliser les filtres utilisateur
      this.gridApi.setFilterModel(historyState[this.pathname]);
    }
  };

  componentWillUnmount() {
    if (!this.props.history.state) {
      this.props.history.state = {};
    }
    if (this.props.history.location.pathname !== "/parcellaire") {
      if (this.gridApi) {
        // sauvegarder les filtres pendant toute la session utilisateur
        this.props.history.state[this.pathname] = this.gridApi.getFilterModel();
      }
    }
  }

  componentDidUpdate() {
    const containerRef = this.containerRef.current;
    if (containerRef) {
      if (containerRef.clientWidth > 0 && !this.state.resized) {
        this.resizeGrid({
          type: "componentDidUpdate",
          api: this.gridApi,
          columnApi: this.columnApi,
        });
      }
    }
  }

  storeGridApi = (params) => {
    // Store grid api in order to be able to call it later
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    // Table fill available screen width
    // if (this.props.tabResultat) {
    // let me = this;
    // setTimeout(function () {
    //   me.synt();
    // }, 1000);
    // }
    if (this.props.tableauFiltrable) {
      // expand all group rows
      this.gridApi.expandAll();
    }
    this.setGridFilters();
  };

  // la fonction resizeGrid peut être appelée par :
  // onFirstDataRendered : tableau affiché dans un container ayant une largeur > 0
  // componentDidUpdate : tableau affiché dans un onglet actif ayant une largeur > 0
  // si resizeGrid est appelée au moment où le container du tableau a une largeur de 0 (onglet inactif) -> échec du redimensionnement
  resizeGrid = (e) => {
    const containerRef = this.containerRef.current;
    if (containerRef && containerRef.clientWidth > 0 && !this.state.resized) {
      if (
        this.props.specificConfig.largeurColonnes &&
        this.props.specificConfig.largeurColonnes === "largeurEcran"
      ) {
        this.resizeColumnsToFit(e);
      } else {
        this.resizeColumnsAuto(e);
        this.resizeGridWidth(e);
      }
    }
  };

  resizeColumnsToFit = (e) => {
    if (e.api && !this.state.resized) {
      e.api.sizeColumnsToFit();
      this.setState({ resized: true });
    }
  };

  resizeColumnsAuto = (e) => {
    if (e.columnApi && !this.state.resized) {
      const allColIds = e.columnApi.getColumns().map((column) => column.colId);
      // Auto-Size All (chaque colonne est redimensionnée en fonction son contenu)
      e.columnApi.autoSizeColumns(allColIds, false);
      this.setState({ resized: true });
    }
    // const allColIds = this.columnApi.getColumns().map((column) => column.colId);
    // // Auto-Size All (chaque colonne est redimensionnée en fonction son contenu)
    // this.columnApi.autoSizeColumns(allColIds, false);
    // // Auto-Size All (Skip Header)
    // // this.columnApi.autoSizeColumns(allColIds, true);
    // // custom (chaque colonne est redimensionnée en fonction son contenu)
    // // this.columnApi.autoSizeAllColumns();
  };

  resizeGridWidth = (e) => {
    if (e.columnApi) {
      const columns = e.columnApi.getAllDisplayedColumns();
      let width = 0;
      columns.forEach((c) => {
        width += c.actualWidth;
      });
      const maxWidth = window.innerWidth;
      if (width > maxWidth || width === 0) {
        width = "100%";
      } else {
        width += 45;
      }
      this.setState({ width });
    }
  };

  handleRowClick = (params) => {
    if (this.props.rowClickCallback) {
      this.props.rowClickCallback(params.data);
    }
  };

  export = async () => {
    const localData = JSON.parse(localStorage.data);
    const title = this.props.specificConfig.title;
    const fileName = `${title} - Campagne ${localData.campagneActive.label}`;
    const author = `${localData.utilisateur.prenom} ${localData.utilisateur.nom}`;

    // header du fichier d'export excel (texte)
    const excelHeader = [
      {
        cells: [
          {
            data: {
              value: "Céré d’Oc 2.0",
              type: "String",
            },
          },
        ],
      },
      {
        cells: [
          {
            data: {
              value: fileName,
              type: "String",
            },
          },
        ],
      },
      {
        cells: [
          {
            data: {
              value: `${author} - ${moment().format("DD-MM-YYYY")}`,
              type: "String",
            },
          },
        ],
      },
      [],
    ];

    // pour l'instant, désactiver l'affichage du logo Céré d'Oc dans l'export Excel
    // non compatible avec open office et libre office
    const isPpf = false;
    // const isPpf = title === "PPF détaillé";
    const base64logo = await toDataURL(logoPPf);

    // pour les ppf uniquement, ajouter le logo en 1ere ligne
    if (isPpf) {
      excelHeader.unshift({
        cells: [
          {
            data: {
              type: "String",
              value: base64logo,
            },
            mergeAcross: isPpf ? 2 : 0,
          },
        ],
      });
    }

    this.gridApi.exportDataAsExcel({
      fileName: fileName,
      author: author,
      prependContent: excelHeader,
      rowHeight: (params) => (params.rowIndex === 1 ? (isPpf ? 200 : 30) : 30),
      addImageToCell: (rowIndex, col, value) => {
        if (rowIndex !== 1) {
          return;
        }

        if (rowIndex === 1 && !isPpf) {
          return;
        }

        return {
          image: {
            id: "base64logo",
            base64: value,
            imageType: "png",
            width: isPpf ? 190 : 208,
            height: isPpf ? 190 : 49,
            // fitCell: true,
            position: {
              colSpan: isPpf ? 2 : 2,
              offsetX: 5,
              offsetY: isPpf ? 5 : 16,
            },
          },
        };
      },
    });
  };

  openPDF = () => {
    if (this.props.urlPDF) {
      // bug iOS : ouvrir la window d'abord
      let newWindow = window.open();
      // puis modifier l'url de la window ensuite
      newWindow.location = this.props.urlPDF;
    }
  };

  removeFilters = () => {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
    }
  };

  detail = () => {
    if (this.props.specificConfig.columnDefs) {
      this.props.specificConfig.columnDefs.forEach((e) =>
        this.columnApi.setColumnVisible(e.field, true)
      );
    }
    this.gridApi.sizeColumnsToFit();
  };

  synt = () => {
    //console.log(this);
    let showTab = [];
    let hideTab = [];
    if (
      this.props.specificConfig.columnDefs !== undefined &&
      this.props.specificConfig.synthese !== undefined
    ) {
      this.props.specificConfig.columnDefs.forEach((e) => {
        hideTab.push(e.field);
        this.props.specificConfig.synthese.forEach((colAfficher) => {
          if (colAfficher === e.field) {
            showTab.push(colAfficher);
          }
        });
      });
      this.columnApi.setColumnsVisible(hideTab, false); //cache tout.
      this.columnApi.setColumnsVisible(showTab, true); //affiche uniquement les colones renseignées dans synthese.
      this.columnApi.autoSizeColumns(showTab, true);
    }
    this.gridApi.sizeColumnsToFit();
  };

  getRowData = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  setRowData = (data) => {
    this.gridApi.setRowData(data);
  };

  render() {
    return (
      <Container
        ref={this.containerRef}
        fluid
        style={{ height: "100%", paddingBottom: 15 }}
        // ne fonctionne pas pour les tableaux dans parcellaire > onglet tableau
        // className="fillAvailableHeight"
        // style={{ paddingBottom: 15 }}
      >
        <Row className="pt-3" style={{ height: "100%" }}>
          {/* ne fonctionne pas pour les tableaux dans parcellaire > onglet tableau */}
          {/* <Row className="fillAvailableHeight pt-3"> */}
          <Col
            className="fillAvailableHeight"
            style={{
              alignItems: this.props.leftAligned ? "start" : "center",
            }}
          >
            <div
              className="fillAvailableHeight"
              style={{
                width: this.state.width,
              }}
            >
              {this.props.toggleColumns && (
                <Col className="d-flex justify-content-between mt-2">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={this.synt}
                      variant="secondary"
                      className="mr-2"
                    >
                      Vue synthétique
                    </Button>
                    <Button onClick={this.detail} variant="secondary">
                      Vue détaillée
                    </Button>
                  </div>
                </Col>
              )}
              {this.props.buttons && (
                <div className="d-flex justify-content-between">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {this.props.buttons}
                  </div>
                </div>
              )}
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <h4>{this.props.specificConfig.title}</h4>
                </div>
                <div>
                  {!this.props.tableauFiltrable && (
                    <Button onClick={this.removeFilters} variant="danger">
                      Supprimer filtres
                      <GiCancel size={20} className="ml-2" />
                    </Button>
                  )}
                  {this.props.exportPDF && (
                    <Button
                      onClick={this.openPDF}
                      variant="danger"
                      className="ml-2"
                    >
                      Export PDF
                      <FaFilePdf size={20} className="ml-2" />
                    </Button>
                  )}
                  {this.props.exportExcel && (
                    <Button
                      onClick={this.export}
                      variant="success"
                      className="ml-2"
                    >
                      Export Excel
                      <FaFileExcel size={20} className="ml-2" />
                    </Button>
                  )}
                </div>
              </div>
              <div
                id="grid-wrapper"
                className="ag-theme-balham mt-1"
                style={
                  this.props.height
                    ? { height: this.props.height }
                    : { flexGrow: 1 }
                }
              >
                <AgGridReact
                  {...baseConfig}
                  {...this.props.specificConfig}
                  getRowStyle={this.props.getRowStyle}
                  onGridReady={this.storeGridApi}
                  onFirstDataRendered={this.resizeGrid.bind(
                    "onFirstDataRendered"
                  )}
                  onRowClicked={this.handleRowClick}
                  modules={AllModules}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const toDataURL = async (url) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function () {
      reject();
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

// exporter le composant grid avec le routeur et la ref (sinon les autres composants ne peuvent pas communiquer avec)
const withRouterInnerRef = (WrappedComponent) => {
  class InnerComponentWithRef extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const ComponentWithRef = withRouter(InnerComponentWithRef, { withRef: true });

  return React.forwardRef((props, ref) => {
    return <ComponentWithRef {...props} forwardRef={ref} />;
  });
};

export default withRouterInnerRef(Grid);
