import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, Alert, Button, Modal } from "react-bootstrap";
// custom component
import Accordions from "stocks/accordions/component";
// react-icons
import { MdEdit, MdDelete } from "react-icons/md";
// momentjs
import moment from "moment";

class createMouvementStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
        title: "",
        body: "",
        cancelBtn: "",
        validateBtn: "",
        validateFn: undefined,
        validateBtnVariant: "",
      },
    };
    const id =
      this.props.history.location.state && this.props.history.location.state.id;
    if (id) {
      this.state.mouvement = { ...this.props.history.location.state };
    } else {
      this.state.mouvement = {
        // id: 1,
        date: moment().format("YYYY-MM-DD"),
        typeDeMouvementId: 1,
        produits: [],
        fournisseur: "",
        numeroFacture: "",
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateReactSelectItem = this.updateReactSelectItem.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  isUpdate() {
    return (
      this.props.history.location.state && this.props.history.location.state.id
    );
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    this.setState((state) => {
      let newValue = value;
      if (type === "select-one") {
        if (value) {
          const nativeTarget = e.nativeEvent.target;
          const index = nativeTarget.selectedIndex;
          const label = nativeTarget[index].text;
          // ajouter l'id et le label de l'option sélectionnée
          newValue = [{ id: Number(value), label: label }];
        } else {
          newValue = [];
        }
      } else if (type === "number" && value !== "") {
        // tronquer au nombre de décimales souhaité
        const decimals = target.getAttribute("decimals");
        newValue = +Number(target.value).toFixed(decimals);
      } else if (type === "radio") {
        newValue = Number(value);
      }
      state.mouvement[name] = newValue;
      return state;
    });
  }

  handleReactSelectChange(name, options) {
    this.setState((state) => {
      state.mouvement[name] = options;
      return state;
    });
  }

  updateItem(e) {
    e.persist();
    const produitId = Number(e.target.getAttribute("data-id"));
    let propName = e.target.name;
    if (propName.includes("typeSemence")) {
      propName = "typeSemence";
    }
    let value = e.target.value;
    if (e.target.type === "radio") {
      value = Number(value);
    }
    this.setState((state) => {
      const produitIndex = this.state.mouvement.produits.findIndex(
        (produit) => produit.id === produitId
      );
      if (produitIndex !== -1) {
        state.mouvement.produits[produitIndex][propName] = value;
      }
      return state;
    });
  }

  updateReactSelectItem(produitId, option) {
    this.setState((state) => {
      const produitIndex = this.state.mouvement.produits.findIndex(
        (produit) => produit.id === produitId
      );
      if (produitIndex !== -1) {
        state.mouvement.produits[produitIndex].traitementSemence = [option];
      }
      return state;
    });
  }

  validate() {
    const mouvement = { ...this.state.mouvement };
    // prix unitaire facultatif = 0 si non renseigné
    mouvement.produits.forEach((p) => {
      if (!p.prixUnitaire) {
        p.prixUnitaire = "0";
      }
    });
    if (this.isValid(mouvement)) {
      this.save();
    }
  }

  isValid(mouvement) {
    let isValid = true;
    mouvement.produits.forEach((produit) => {
      if (!produit.quantite) {
        isValid = false;
        this.showError(`Produit ${produit.label} : quantité non renseignée.`);
      }
    });
    return isValid;
  }

  showError(message) {
    this.props.data.showNotification({
      type: "danger",
      title: message,
    });
  }

  async save() {
    let mouvement = { ...this.state.mouvement };
    let requestName;
    if (mouvement.id) {
      requestName = "update";
    } else {
      requestName = "create";
    }
    const response = await this.props.data.sendRequest(requestName, {
      listName: "mouvementsStocks",
      item: mouvement,
    });
    if (response) {
      this.goToList();
    }
  }

  showModal() {
    this.setState({ modal: { show: true } });
  }

  hideModal() {
    this.setState({ modal: { show: false } });
  }

  async delete() {
    const { id } = this.props.history.location.state;
    const response = await this.props.data.sendRequest("delete", {
      itemId: id,
      listName: "mouvementsStocks",
    });
    if (response.success) {
      this.goToList();
    }
  }

  goToList() {
    this.props.history.goBack();
  }

  render() {
    const isUpdate = this.isUpdate();
    const { modal } = this.state;
    return (
      <Container>
        <Row>
          <Alert
            variant={isUpdate ? "warning" : "success"}
            className="text-center w-100 mb-0"
          >
            <Alert.Heading>
              {isUpdate
                ? "Modifier un mouvement de stock"
                : "Créer un mouvement de stock"}
            </Alert.Heading>
          </Alert>
        </Row>
        <Row>
          <Accordions
            isUpdate={isUpdate}
            mouvement={this.state.mouvement}
            handleChange={this.handleChange}
            handleReactSelectChange={this.handleReactSelectChange}
            updateItem={this.updateItem}
            updateReactSelectItem={this.updateReactSelectItem}
            history={this.props.history}
          />
        </Row>
        <Row className="mt-3 mb-3">
          <Col className="d-flex justify-content-end">
            {isUpdate && (
              <Button variant="danger" onClick={() => this.showModal()}>
                Supprimer le mouvement
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
            <Button variant="warning" onClick={this.validate} className="ml-2">
              Enregistrer
              <MdEdit size={25} className="ml-2" />
            </Button>
          </Col>
        </Row>
        <Modal centered show={modal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer ce mouvement de stock ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.hideModal}>
              Annuler
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.delete();
                this.hideModal();
              }}
            >
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(createMouvementStock);
