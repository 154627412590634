export const Helper = {
  // Fertilisation, Traitement Phyto, Semis, Autres produits
  isProduits: interventionTypeId => {
    return [61, 60, 76, 1000115].includes(interventionTypeId);
  },

  // !Observation, !Autres produits, !Autres charges
  isStatut: interventionTypeId => {
    return ![91, 1000115, 1000116].includes(interventionTypeId);
  },

  // !Observation, !Autres produits, !Autres charges
  isMateriel: interventionTypeId => {
    return ![91, 1000115, 1000116].includes(interventionTypeId);
  },

  // !Autres produits, !Autres charges
  isMainOeuvre: interventionTypeId => {
    return ![1000115, 1000116].includes(interventionTypeId);
  },

  // Autres produits, Autres charges
  isLibelle: interventionTypeId => {
    return [1000115, 1000116].includes(interventionTypeId);
  },

  // Travail du sol, Façon culturale, Transport
  isTypeOperation: interventionTypeId => {
    return [75, 90, 92].includes(interventionTypeId);
  },

  // Traitement Phyto
  isTraitementPhyto: interventionTypeId => {
    return interventionTypeId === 60;
  },

  // Fertilisation
  isFertilisation: interventionTypeId => {
    return interventionTypeId === 61;
  },

  // Irrigation
  isIrrigation: interventionTypeId => {
    return interventionTypeId === 63;
  },

  // Semis
  isSemis: interventionTypeId => {
    return interventionTypeId === 76;
  },

  // Récolte
  isRecolte: interventionTypeId => {
    return interventionTypeId === 82;
  },

  // Autres charges
  isCharges: interventionTypeId => {
    return interventionTypeId === 1000116;
  }
};
