import React, { useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// react-bootstrap components
import { Row, Col, ListGroup, Badge } from "react-bootstrap";
// intervention datas
import { listeTypesIntervention, listeStatuts } from "./datas.js";
// intervention helper
import { Helper } from "./helpers.js";
// momentjs
import "moment/locale/fr";
import moment from "moment";
import Icon from "../icons/icon.js";

const Intervention = ({ list, handleClick }) => {
  const { typesOperation } = useContext(DataContext);

  const handleClick2 = (e) => {
    const id = Number(e.currentTarget.getAttribute("data-id"));
    const interventionTypeId = Number(
      e.currentTarget.getAttribute("data-intervention-type-id")
    );
    handleClick({ id, interventionTypeId });
  };

  const getSurfaceTotale = (intervention) => {
    return intervention.parcelles.reduce((total, currentValue) => {
      let surfaceTotale = Number(total) + Number(currentValue.surface);
      return surfaceTotale.toFixed(2);
    }, 0);
  };

  return list.map((intervention, index) => {
    const typeIntervention = listeTypesIntervention.find(
      (item) => item.id === intervention.typeId
    );
    const jour = moment(intervention.date).format("D");
    const mois = moment(intervention.date).format("MMMM");
    const annee = moment(intervention.date).format("YYYY");
    const statut = listeStatuts.find(
      (item) => item.value === intervention.statutId
    );
    let typeOperation;
    if (typesOperation[intervention.typeId]) {
      typeOperation = typesOperation[intervention.typeId].find(
        (item) => item.value === intervention.typeOperationId
      );
    }
    const isProduits = Helper.isProduits(intervention.typeId);
    const isCharges = Helper.isCharges(intervention.typeId);
    const isSemis = Helper.isSemis(intervention.typeId);
    const isRecolte = Helper.isRecolte(intervention.typeId);
    const isIrrigation = Helper.isIrrigation(intervention.typeId);
    return (
      <ListGroup.Item
        key={intervention.id}
        data-id={intervention.id}
        data-intervention-type-id={intervention.typeId}
        as="li"
        action
        onClick={handleClick2}
        style={{ cursor: "pointer" }}
      >
        <Row>
          <Col md={2} className="d-none d-sm-none d-md-block">
            <div
              className="d-flex flex-column align-items-center justify-content-center bg-light rounded"
              style={{ width: 100, height: "100%" }}
            >
              <h6>
                {jour} {mois}
              </h6>
              <h6> {annee} </h6>
            </div>
          </Col>
          <Col xs={10} md={8}>
            <div className="d-flex flex-row flex-wrap mb-1 d-none d-block d-sm-block d-md-none">
              <h6 className="mb-0">{`${jour} ${mois} ${annee}`}</h6>
            </div>
            <div className="d-flex flex-row flex-wrap mb-1">
              <h6 className="mb-0">{`${
                typeIntervention.label
              } - ${getSurfaceTotale(intervention)}ha`}</h6>
            </div>
            <div className="d-flex flex-row flex-wrap mb-1">
              {intervention.parcelles.map(
                (parcelle, index) =>
                  index <= 3 && (
                    <h6 key={parcelle.id} className="mr-1 mb-0">
                      <Badge variant="warning">
                        {index < 3
                          ? parcelle.label
                          : `+${intervention.parcelles.length - index}`}
                      </Badge>
                    </h6>
                  )
              )}
              {intervention.cultures.map(
                (culture, index) =>
                  index <= 3 && (
                    <h6 key={culture.id} className="mr-1 mb-0">
                      <Badge variant="success">
                        {index < 3
                          ? culture.label
                          : `+${intervention.cultures.length - index}`}
                      </Badge>
                    </h6>
                  )
              )}
            </div>
            <div className="d-flex flex-wrap">
              {isProduits &&
                intervention.produits.map((produit, index) => {
                  let quantite =
                    isSemis && produit.traitement
                      ? produit.quantite
                      : produit.quantitePerSurface;
                  let unit =
                    isSemis && produit.traitement
                      ? produit.unit
                      : `${produit.unit}/ha`;
                  return (
                    index <= 3 && (
                      <h6 key={produit.id} className="mb-0 mr-1">
                        <Badge variant="info">
                          {index <= 2
                            ? `${produit.label} ${quantite} ${unit}`
                            : `+${intervention.produits.length - index}`}
                        </Badge>
                      </h6>
                    )
                  );
                })}
              {isCharges &&
                intervention.charges.map(
                  (charge, index) =>
                    index <= 3 && (
                      <h6 key={charge.id} className="mb-0 mr-1">
                        <Badge variant="info">
                          {index <= 2
                            ? charge.label
                            : `+${intervention.charges.length - index}`}
                        </Badge>
                      </h6>
                    )
                )}
              {isRecolte && (
                <BadgeRecolte
                  recoltes={intervention.recoltes}
                  cultures={intervention.cultures}
                />
              )}
              {isIrrigation && (
                <h6 className="mb-0 mr-1">
                  <Badge variant="info">
                    {`${intervention.quantiteEau} mm`}
                  </Badge>
                </h6>
              )}
              {typeOperation !== undefined && (
                <Badge variant="info" className="mb-0 mr-1">
                  {typeOperation.label}
                </Badge>
              )}
            </div>
          </Col>
          <Col xs={2}>
            <div className="d-flex align-items-end flex-column text-secondary">
              {Helper.isStatut(intervention.typeId) && (
                <h5>
                  <Badge variant={statut.color}>{statut.label}</Badge>
                </h5>
              )}
              <div className="mt-auto">
                <Icon
                  iconName={typeIntervention.icon}
                  size={40}
                  float="right"
                />
              </div>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  });
};

export default Intervention;

const BadgeRecolte = ({ recoltes, cultures }) => {
  const recolte = recoltes[0];
  const culture = cultures[0];
  let quantitePerSurfaceRecolte = "";
  let labelCulture = "";
  if (recoltes && recoltes[0]) {
    quantitePerSurfaceRecolte = recoltes[0].quantitePerSurface;
  }
  if (cultures && cultures[0]) {
    labelCulture = culture.label;
  }
  if (recolte) {
    return (
      <h6 className="mb-0 mr-1">
        <Badge variant="info">{`${labelCulture} ${quantitePerSurfaceRecolte} T/ha`}</Badge>
      </h6>
    );
  } else {
    return null;
  }
};
