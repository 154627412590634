import React, { createContext } from "react";
// local DB data functions
import { syncLocalData } from "localStorage";
// errors logger
import * as Sentry from "@sentry/browser";
// composant erreur
import ErrorScreen from "../error/screen";
// listes interventions
import { listeTypesIntervention, listeStatuts } from "../interventions/datas";
// groupes de parcelles
import { getGroupes } from "../parcellaire/utils";
// toast notifications
import NotificationsManager from "notifications/manager";
// loading indicator
import ApiManager from "api/manager";
// loading indicator
import SyncToolbarManager from "toolbar/manager";
// encrypt
import CryptoJSAesEncrypt from "utils/encrypt";

const initialDatas = {
  menu: { left: [], right: [] },
  actualites: [],
  exploitations: [],
  campagnes: [],
  ilots: [],
  groupesParcelles: [],
  parcelles: [],
  telepac: [],
  cultures: [],
  culturepac: [],
  surfaces: {},
  culturesPrecedentes: [],
  varietes: [],
  produits: [],
  typeCulture: [],
  destination: [],
  typeDeSol: [],
  interventions: [],
  materiels: [],
  mainOeuvres: [],
  typesOperation: {},
  parametres: {
    stockPrestataire: false,
    parcellaireModifiable: false,
    exploitationAssociee: null,
  },
};

export const DataContext = createContext({
  ...initialDatas,
  utilisateur: {},
  exploitationActive: {},
  campagneActive: {},
  allParcelles: [],
  parcellesMode: 1,
  allInterventions: [],
  fromLogin: false,
  queuedRequests: 0,
});

class DataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.apiManager = React.createRef();
    this.notificationsManager = React.createRef();
    this.syncToolbarManager = React.createRef();
    this.state = {
      ...initialDatas,
      // utilisateur & campagneActive provenant de l'url ou du cache
      ...props.data,
      parcellesMode: 1,
      isError: false,
      errorData: {},
      login: async (email, password) => {
        const isSuccess = this.state
          .sendRequest("login", { email, password })
          .then((data) => {
            if (data.utilisateur && data.utilisateur.id) {
              data = transform({ data });
              // Store data in cache
              return syncLocalData(data).then(() => {
                this.setState((state) => {
                  state = { ...state, ...data };
                  state.fromLogin = true;
                  return state;
                });
                return data;
              });
            } else {
              return data;
            }
          });
        return isSuccess;
        // let data = await this.state.sendRequest("login", email, password);
        // if (data.utilisateur && data.utilisateur.id) {
        //   data = transform(data);
        //   // Store data in cache
        //   await syncLocalData(data);
        //   this.setState((state) => {
        //     state = { ...state, ...data };
        //     state.fromLogin = true;
        //     return state;
        //   });
        //   return data;
        // } else {
        //   return data;
        // }
      },
      sync: async (showSyncToolbar) => {
        let endState = {};
        const fromLogin = this.state.fromLogin;
        if (showSyncToolbar) {
          this.syncToolbarManager.current.showToolbar();
        }
        // exécuter les requêtes en file d'attente
        // actions effectuées en mode déconnecté (workbox backgroundSync)
        let queuedRequests = 0;
        try {
          const data = await replayQueue();
          if (Number.isInteger(data.queuedRequests)) {
            queuedRequests = data.queuedRequests;
          }
        } catch (error) {
          console.error(error);
        }
        endState.queuedRequests = queuedRequests;
        this.syncToolbarManager.current.set({ queuedRequests: queuedRequests });
        // ne pas rafraîchir les données s'il reste des requêtes en file d'attente
        // permet de garder les interventions non synchronisées dans la liste de l'utilisateur
        // if (!queuedRequests) {
        // if (!this.state.campagnes || (navigator.onLine && !queuedRequests)) {
        // récupérer les données serveur
        if (!fromLogin) {
          let data = await this.state.sendRequest("fetch");
          console.log("fetch request - response :");
          console.log(data);
          const isSuccess = data.utilisateur && data.utilisateur.id;
          if (isSuccess) {
            data = transform({
              data,
              campagneId: this.state.campagneActive.id,
            });
            await syncLocalData(data);
            endState = { ...this.state, ...data };
          }
        } else {
          endState.fromLogin = false;
        }
        this.setState(endState, () => {
          this.syncToolbarManager.current.set({ syncStatus: "completed" });
          this.state.showNotification({
            type: "warning",
            title: `Vous travaillez sur la campagne ${this.state.campagneActive.label}`,
            autoclose: true,
          });
        });
      },
      addItem: async (listName, item) => {
        const createdItem = await this.state.sendRequest("create", {
          listName,
          item,
        });
        const isSuccess = createdItem.id || createdItem.properties.id;
        if (!isSuccess) {
          // mettre un id temporaire (négatif)
          const count = this.state[listName].filter(
            (listItem) => listItem.id < 0
          ).length;
          const id = -count - 1;
          if (item.properties) {
            item.properties.id = id;
          } else {
            item.id = id;
          }
        }
        this.setState(
          {
            [listName]: [...this.state[listName], { ...item }],
          },
          () => {
            // pour les parcelles
            if (listName === "parcelles") {
              // modifier les surfaces cultures & totales
              this.state.updateSurfaces();
              // actualiser la liste des groupes de parcelles
              this.state.generateGroupesParcelles();
              // pour les groupes de parcelles, trier les groupes manuels et auto
            } else if (listName === "groupesParcelles") {
              this.state.sortGroupesParcelles();
            }
          }
        );
        return true;
      },
      updateItem: async (listName, item) => {
        await this.state.sendRequest("update", { listName, item });
        this.setState(
          (state) => {
            const index = state[listName].findIndex((itemInList) => {
              if (itemInList.properties) {
                return itemInList.properties.id === item.properties.id;
              } else {
                return itemInList.id === item.id;
              }
            });
            state[listName][index] = item;
            return state;
          },
          () => {
            // pour les parcelles
            if (listName === "parcelles") {
              // modifier les surfaces cultures & totales
              this.state.updateSurfaces();
              // actualiser la liste des groupes de parcelles
              this.state.generateGroupesParcelles();
              // pour les groupes de parcelles, trier les groupes manuels et auto
              this.state.sortGroupesParcelles();
            }
          }
        );
        return true;
      },
      updateItems: async (listName, list, items) => {
        await this.state.sendRequest("bulkUpdate", { listName, items });
        // TODO : enlever les props isActive ?
        // copier la liste concernée
        let updatedList = [...this.state[listName]];
        // pour chaque item de la liste
        list.forEach((item) => {
          // trouver l'index correspondant
          const index = updatedList.findIndex(
            (p) => p.properties.id === item.properties.id
          );
          // si index trouvé
          if (index !== -1) {
            // remplacer l'item dans la liste
            updatedList[index] = item;
          }
        });
        this.setState({ [listName]: updatedList }, () => {
          // pour les parcelles
          if (listName === "parcelles") {
            // modifier les surfaces cultures & totales
            this.state.updateSurfaces();
            // actualiser la liste des groupes de parcelles
            this.state.generateGroupesParcelles();
            // pour les groupes de parcelles, trier les groupes manuels et auto
            this.state.sortGroupesParcelles();
          }
        });
        return true;
      },
      removeItem: async (listName, itemId) => {
        const response = await this.state.sendRequest("delete", {
          listName,
          itemId,
        });
        if (!response.error || response.error !== "intervention") {
          this.setState(
            {
              [listName]: this.state[listName].filter(
                (item) =>
                  (item.properties ? item.properties.id : item.id) !== itemId
              ),
            },
            () => {
              // pour les parcelles
              if (listName === "parcelles") {
                // modifier les surfaces cultures & totales
                this.state.updateSurfaces();
                // actualiser la liste des groupes de parcelles
                this.state.generateGroupesParcelles();
              }
            }
          );
        }
        return response;
      },
      updateSurfaces: () => {
        this.setState({
          surfaces: getSurfaces(this.state.parcelles),
          cultures: getCultures(this.state.parcelles),
        });
      },
      generateGroupesParcelles: () => {
        this.setState({
          groupesParcelles: getGroupes({
            parcelles: this.state.parcelles,
            cultures: this.state.cultures,
            exploitations: this.state.exploitations,
          }),
        });
      },
      sortGroupesParcelles: () => {
        this.setState({
          // eslint-disable-next-line array-callback-return
          groupesParcelles: this.state.groupesParcelles.sort((a, b) => {
            // groupes manuels (id number) en haut
            if (typeof a.id === "number" && typeof b.id === "string") {
              return -1;
              // groupes autos en bas (id string)
            } else if (typeof a.id === "string" && typeof b.id === "number") {
              return 1;
            }
          }),
        });
      },
      toggleParcellesExploitation: (value) => {
        // deep copy sinon clone
        let parcelles = JSON.parse(JSON.stringify(this.state.allParcelles));
        let interventions = JSON.parse(
          JSON.stringify(this.state.allInterventions)
        );
        if (value === 2) {
          const exploitationId = this.state.parametres.exploitationAssociee;
          // filtrer les parcelles correspondantes à l'exploitation sélectionnée
          parcelles = parcelles.filter((p) =>
            p.properties.exploitation.some((e) => e.id === exploitationId)
          );
          // filtrer les interventions correspondantes
          const parcellesIds = parcelles.map((p) => p.properties.id);
          interventions = interventions.filter((i) =>
            i.parcelles.some((p) => parcellesIds.includes(p.id))
          );
        }
        // filtrer les autres propriétés
        const cultures = getCultures(parcelles);
        const groupesParcelles = getGroupes({
          parcelles: parcelles,
          cultures: cultures,
          exploitations: this.state.exploitations,
        });
        this.setState({
          parcellesMode: value,
          parcelles: parcelles,
          cultures: cultures,
          culturesPrecedentes: getCulturesPrecedentes(parcelles),
          varietes: getVarietes(parcelles),
          surfaces: getSurfaces(parcelles),
          groupesParcelles: groupesParcelles,
          groupesParcelles2: getGroupesParcelles2(groupesParcelles),
          interventions: interventions,
        });
      },
      update: async ({ type, id }) => {
        return new Promise((resolve) => {
          this.setState(
            (state) => {
              state[
                type === "campagne" ? "campagneActive" : "exploitationActive"
              ].id = id;
              return state;
            },
            () => {
              const isSuccess = this.state.sendRequest("fetch").then((data) => {
                if (data.utilisateur && data.utilisateur.id) {
                  const campagneId =
                    type === "campagne" ? id : this.state.campagneActive.id;
                  const exploitationId =
                    type === "exploitation"
                      ? id
                      : this.state.exploitationActive.id;
                  data = transform({ data, campagneId, exploitationId });
                  console.log("update request - response :");
                  console.log(data);
                  // Store data in cache
                  return syncLocalData(data).then(() => {
                    this.setState((state) => {
                      state = { ...state, ...data };
                      return state;
                    });
                    return true;
                  });
                } else {
                  return false;
                }
              });
              resolve(isSuccess);
            }
          );
        });
      },
      showNotification: (notification) => {
        this.notificationsManager.current.showNotification(notification);
      },
      updateQueuedRequests: (queuedRequests) => {
        let queuedRequestsNb = Number.isInteger(queuedRequests)
          ? queuedRequests
          : this.state.queuedRequests + 1;
        this.setState({
          queuedRequests: queuedRequestsNb,
        });
        this.syncToolbarManager.current.set({
          queuedRequests: queuedRequestsNb,
        });
      },
      sendRequest: async (name, params) => {
        // pour toutes les requêtes autres que login
        if (name !== "login") {
          // rajouter les params requis pour chaque requete
          params = {
            ...params,
            id_utilisateur: this.state.utilisateur.id,
            id_campagne: this.state.campagneActive.id,
            id_exploitation: this.state.exploitationActive.id,
          };
          // si requete fetch, rajouter le token
          if (name === "fetch") {
            params.token = this.state.generateToken();
          }
        }
        return await this.apiManager.current.sendRequest(name, params);
      },
      generateToken: () => {
        const token = CryptoJSAesEncrypt(
          process.env.REACT_APP_PP,
          JSON.stringify({
            id_utilisateur: this.state.utilisateur.id,
            id_campagne: this.state.campagneActive.id,
            id_exploitation: this.state.exploitationActive.id,
          })
        );
        return token;
      },
      deconnexion: () => {
        // supprimer le cache
        localStorage.setItem("data", JSON.stringify({}));
        // recharger la page (pour repasser par index.js)
        window.location.replace("/");
        // attendre 3 secondes avant de reset les datas (pour ne pas voir la page actuelle sans UI)
        setTimeout(() => {
          this.setState({
            ...initialDatas,
            utilisateur: {},
            campagneActive: {},
            exploitationActive: {},
          });
        }, 3000);
      },
    };
  }

  componentDidCatch(error, errorInfo) {
    // envoyer l'erreur au service sentry (automatisation de réception d'erreurs)
    Sentry.withScope((scope) => {
      scope.setUser({
        id: this.state.utilisateur.id,
        username: `${this.state.utilisateur.prenom} ${this.state.utilisateur.nom}`,
      });
      scope.setExtra("campagne", this.state.campagneActive.id);
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    // stocker les erreurs dans le state (pour les afficher sur la page d'erreur)
    this.setState({
      isError: true,
      errorData: {
        url: window.location.href,
        utilisateur: this.state.utilisateur,
        campagne: this.state.campagneActive,
        error: error,
        stacktrace: errorInfo.componentStack.split("    "),
      },
    });
  }

  render() {
    return !this.state.isError ? (
      <>
        <ApiManager ref={this.apiManager} />
        <NotificationsManager ref={this.notificationsManager} />
        <SyncToolbarManager ref={this.syncToolbarManager} />
        <DataContext.Provider value={this.state}>
          {this.props.children}
        </DataContext.Provider>
      </>
    ) : (
      <ErrorScreen errorData={this.state.errorData} />
    );
  }
}

export default DataProvider;

export const WithContext = (Component) => {
  //   return props => (
  //     <DataContext.Consumer>
  //       {value => <Component {...props} data={value} />}
  //     </DataContext.Consumer>
  //   );
  return React.forwardRef((props, ref) => {
    return (
      <DataContext.Consumer>
        {(value) => <Component {...props} ref={ref} data={value} />}
      </DataContext.Consumer>
    );
  });
};

const transform = ({ data, campagneId, exploitationId }) => {
  data.campagneActive = setCampagneActive(data, campagneId);
  data.exploitationActive = setExploitationActive(data, exploitationId);
  data.allParcelles = data.parcelles;
  data.allInterventions = data.interventions;
  data.cultures = getCultures(data.parcelles);
  data.culturesPrecedentes = getCulturesPrecedentes(data.parcelles);
  data.surfaces = getSurfaces(data.parcelles);
  data.groupesParcelles = getGroupes({
    parcelles: data.parcelles,
    cultures: data.cultures,
    exploitations: data.exploitations,
  });
  data.groupesParcelles2 = getGroupesParcelles2(data.groupesParcelles);
  data.varietes = getVarietes(data.parcelles);
  data.typesIntervention = listeTypesIntervention;
  data.listeStatuts = listeStatuts;
  return data;
};

const setCampagneActive = (data, campagneId) => {
  // select this campaign
  let campagne = data.campagnes.find((c) => c.id === Number(campagneId));
  if (!campagne) {
    // select campaign sent by server
    campagne = data.campagnes.find((c) => c.id === data.id_campagne);
  }
  if (!campagne) {
    // select most recent campaign
    campagne = data.campagnes.reduce(
      (prev, current) => (prev.id > current.id ? prev : current),
      0
    );
  }
  return { ...campagne };
};

const setExploitationActive = (data, exploitationId) => {
  // select this exploitation
  let exploitation = data.exploitations.find(
    (e) => e.id === Number(exploitationId)
  );
  if (!exploitation) {
    // select principal exploitation
    exploitation = data.exploitations.find((e) => e.principal === true);
  }
  return { ...exploitation };
};

function replayQueue() {
  return new Promise(function (resolve, reject) {
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: "REPLAY_QUEUE",
      });
    } else {
      reject("sync - sw is not ready - queue can't be replayed");
    }
  });
}

const getCultures = (parcelles) => {
  const cultures = [];
  // pour chaque parcelle
  parcelles.forEach((parcelle) => {
    const cultureParcelle = parcelle.properties.culture[0];
    const surfaceParcelle = parcelle.properties.surface;
    // si la culture de cette parcelle n'est pas encore présente dans la liste de cultures
    if (!cultures.some((item) => item.id === cultureParcelle.id)) {
      // rajouter la culture
      cultures.push({
        ...cultureParcelle,
        surfaceTotale: 0,
      });
    }
    // rajouter la surface de la parcelle à la culture correspondante
    let culture = cultures.find((item) => item.id === cultureParcelle.id);
    culture.surfaceTotale =
      Math.round((culture.surfaceTotale + surfaceParcelle) * 100) / 100;
  });
  // console.log(JSON.stringify(cultures, 0, 2));
  return cultures;
};

const getCulturesPrecedentes = (parcelles) => {
  const cultures = [];
  // pour chaque parcelle
  parcelles.forEach((parcelle) => {
    const cultureParcelle = parcelle.properties.culturePrecedente[0];
    // si la culture de cette parcelle n'est pas encore présente dans la liste de cultures
    if (!cultures.some((item) => item.id === cultureParcelle.id)) {
      // rajouter la culture
      cultures.push({
        ...cultureParcelle,
      });
    }
  });
  return cultures;
};

const getVarietes = (parcelles) => {
  const varietes = [];
  parcelles.forEach((parcelle) => {
    parcelle.properties.varietes.forEach((variete) => {
      if (!varietes.some((item) => item.id === variete.id)) {
        varietes.push(variete);
      }
    });
  });
  // console.log(JSON.stringify(varietes, 0, 2));
  return varietes;
};

const getSurfaces = (parcelles) => {
  let surfaces = {
    culturesDerobees: 0,
    culturesPrincipales: 0,
    totale: 0,
  };
  // pour chaque parcelle
  parcelles.forEach((parcelle) => {
    const cultures = parcelle.properties.culture;
    const isCulture = Array.isArray(cultures) && cultures[0] && cultures[0].id;
    const typeCultures = parcelle.properties.typeCulture;
    const isTypeCulture =
      Array.isArray(typeCultures) && typeCultures[0] && typeCultures[0].id;
    const surface = parcelle.properties.surface;
    // si type de culture existant
    if (isCulture && isTypeCulture) {
      const typeCultureId = typeCultures[0].id;
      // si culture principale
      if (typeCultureId === 1) {
        surfaces.culturesPrincipales += surface;
        // si culture dérobée
      } else if (typeCultureId === 2) {
        surfaces.culturesDerobees += surface;
      }
    }
    surfaces.totale += surface;
  });
  surfaces.culturesPrincipales = Number(surfaces.culturesPrincipales).toFixed(
    2
  );
  surfaces.culturesDerobees = Number(surfaces.culturesDerobees).toFixed(2);
  surfaces.totale = Number(surfaces.totale).toFixed(2);
  return surfaces;
};

const getGroupesParcelles2 = (groupesParcelles) => {
  // deep copy des groupes de parcelles sinon clone
  let groupesParcelles2 = JSON.parse(JSON.stringify(groupesParcelles));
  groupesParcelles2.forEach((g) => {
    g.parcelles = g.options.map((p) => {
      return { id: p.properties.id, label: p.properties.label };
    });
    delete g.options;
    delete g.checked;
    delete g.color;
    delete g.surfaceTotale;
  });
  return groupesParcelles2;
};
