import React from "react";
// ag-grid component
import Grid from "../ag-grid/grid";
// react-bootstrap components
import {
  Container,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
// momentjs
import "moment/locale/fr";
import moment from "moment";
// intervention datas
import { listeTypesIntervention, listeStatuts } from "interventions/datas.js";

class Switcher extends React.Component {
  constructor() {
    super();
    this.state = { vue: 1 };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.getTableData = this.getTableData.bind(this);
  }

  handleRadioChange(value) {
    this.setState({ vue: value });
  }

  rowClick(rowData) {
    if (rowData) {
      const { id, typeId } = rowData;
      if (id) {
        this.props.rowClickCallback({ id, interventionTypeId: typeId });
      }
    }
  }

  getTableData(config) {
    let { liste } = config;
    let columnDefs;
    if (config.nom === "parcelles") {
      liste = config.liste.map((item) => item.properties);
    }
    columnDefs = configs[config.nom] || [];
    return {
      columnDefs: columnDefs,
      rowData: liste,
    };
  }

  render() {
    const vueListe = this.state.vue === 1;
    const vueTableau = this.state.vue === 2;
    return (
      <>
        <Container fluid className="d-flex justify-content-end pt-3">
          {this.props.buttons}
          <ButtonToolbar className="ml-3">
            <ToggleButtonGroup
              type="radio"
              name="vue"
              defaultValue={this.state.vue}
              onChange={this.handleRadioChange}
            >
              <ToggleButton value={1} variant="light">
                Liste
              </ToggleButton>
              <ToggleButton value={2} variant="light">
                Tableau
              </ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </Container>
        {vueListe && this.props.children}
        {vueTableau &&
          this.props.config.map((config, index) => (
            <Grid
              key={index}
              specificConfig={this.getTableData(config)}
              rowClickCallback={this.rowClick.bind(this)}
              sizeColumnsToFit={config.sizeColumnsToFit}
              leftAligned={this.props.config.length > 1}
              height={config.height}
            />
          ))}
      </>
    );
  }
}

export default Switcher;

const getLabelInArray = (propertyName) => {
  return (params) => {
    if (params.data) {
      const array = params.data[propertyName];
      if (Array.isArray(array) && array.length) {
        return array[0].label;
      } else {
        return "";
      }
    }
  };
};

const getLabelsInArray = (propertyName) => {
  return (params) => {
    if (params.data) {
      const array = params.data[propertyName];
      if (Array.isArray(array) && array.length) {
        let label = "";
        array.forEach((item, index) => {
          if (index > 0) {
            label += " | " + item.label;
          } else {
            label += item.label;
          }
        });
        return label;
      } else {
        return "";
      }
    }
  };
};

const getLabelForBoolean = (propertyName) => {
  return (params) => {
    if (params.data) {
      return params.data[propertyName] === 1 ? "Oui" : "Non";
    }
  };
};

const getLabelForDate = (propertyName) => {
  return (params) => {
    if (params.data) {
      return moment(params.data[propertyName]).format("L");
    }
  };
};

const getLabelForId = (propertyName, list) => {
  return (params) => {
    if (params.data) {
      const id = params.data[propertyName];
      const item = list.find((item) => item.id === id || item.value === id);
      if (item && item.label) {
        return item.label;
      } else {
        return "";
      }
    }
  };
};

const configs = {
  parcelles: [
    { headerName: "Parcelle", field: "label" },
    {
      headerName: "Îlot",
      valueGetter: getLabelInArray("ilot"),
    },
    { headerName: "Surface", field: "surface" },
    {
      headerName: "Culture",
      valueGetter: getLabelInArray("culture"),
    },
    {
      headerName: "Variété",
      valueGetter: getLabelsInArray("varietes"),
    },
    {
      headerName: "Précédent",
      valueGetter: getLabelInArray("culturePrecedente"),
    },
    { headerName: "Rendement prévisionnel", field: "rendement" },
    {
      headerName: "Type de culture",
      valueGetter: getLabelInArray("typeCulture"),
    },
    {
      headerName: "Destination",
      valueGetter: getLabelInArray("destination"),
    },
    {
      headerName: "Type de sol",
      valueGetter: getLabelInArray("typeDeSol"),
    },
    {
      headerName: "Zone vulnérable",
      valueGetter: getLabelForBoolean("zoneVulnerable"),
    },
  ],
  cultures: [
    { headerName: "Cultures", field: "label" },
    { headerName: "Surface totale", field: "surfaceTotale" },
  ],
  surfacesTotales: [
    { headerName: "Type de culture", field: "label" },
    { headerName: "Surface totale", field: "surfaceTotale" },
  ],
  interventions: [
    { headerName: "Date", valueGetter: getLabelForDate("date") },
    {
      headerName: "Type",
      valueGetter: getLabelForId("typeId", listeTypesIntervention),
    },
    {
      headerName: "Statut",
      valueGetter: getLabelForId("statutId", listeStatuts),
    },
    {
      headerName: "Parcelles",
      valueGetter: getLabelsInArray("parcelles"),
    },
    {
      headerName: "Cultures",
      valueGetter: getLabelsInArray("cultures"),
    },
    {
      headerName: "Produits",
      valueGetter: getLabelsInArray("produits"),
    },
    {
      headerName: "Main d'oeuvre",
      valueGetter: getLabelsInArray("mainOeuvres"),
    },
    {
      headerName: "Matériel",
      valueGetter: getLabelsInArray("materiels"),
    },
    { headerName: "Observation", field: "observation" },
  ],
};
