import React, { useState } from "react";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import {
  Container,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
// ag-grid component
import Grid from "ag-grid/grid";
// list component
import List from "generique/liste";

const Tableau = ({ data, history }) => {
  const [vue, setVue] = useState(2);
  const rowClickCallback = (rowData) => {
    if (data.rowClick) {
      history.push(data.rowClick, rowData.data);
    }
  };

  if (data.modeListe) {
    return (
      <>
        <Container fluid className="d-flex justify-content-end mt-3">
          <ButtonToolbar className="ml-3">
            <ToggleButtonGroup
              type="radio"
              name="vue"
              defaultValue={vue}
              onChange={(value) => setVue(value)}
            >
              <ToggleButton value={1} variant="light">
                Liste
              </ToggleButton>
              <ToggleButton value={2} variant="light">
                Tableau
              </ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </Container>
        {vue === 1 && <List data={data} />}
        {vue === 2 && (
          <Grid
            specificConfig={data}
            buttons={data.buttons}
            exportExcel={data.exportCSV}
            exportPDF={data.exportPDF}
            urlPDF={data.urlPDF}
            tableauFiltrable={data.tableauFiltrable}
            rowClickCallback={rowClickCallback}
            height={data.height}
          />
        )}
      </>
    );
  } else {
    return (
      <Grid
        specificConfig={data}
        buttons={data.buttons}
        exportExcel={data.exportCSV}
        exportPDF={data.exportPDF}
        urlPDF={data.urlPDF}
        tableauFiltrable={data.tableauFiltrable}
        getRowStyle={data.getRowStyle}
        rowClickCallback={rowClickCallback}
        height={data.height}
      />
    );
  }
};

export default withRouter(Tableau);
