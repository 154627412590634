import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// other generic components
import Generique from "generique/component";

class Parametrable extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isDataLoaded: false,
    };
  }

  async componentDidMount() {
    // récupérer le nom de la requête dans les params d'url
    const requete = new URLSearchParams(this.props.location.search).get(
      "requete"
    );
    // récupérer la propriété écran dans les params d'url
    const ecran = new URLSearchParams(this.props.location.search).get("ecran");
    // récupérer l'objet json contenant les données à afficher
    const data = await this.props.data.sendRequest(requete, { ecran });
    // console.log("data");
    // console.log(JSON.stringify(data, 0, 2));

    // const tableauFiltrable = {
    //   type: "tableauFiltrable",
    //   config: {
    //     code: "code du tableau...",
    //     titre: "Nom du tableau ...",
    //     sousTitre: "Sélectionnez les critères de préfiltrage du tableau",
    //     filtres: [
    //       {
    //         type: "date",
    //         label: "Date de début",
    //         name: "dateDebut",
    //         // required: true,
    //         // value: "2022-11-24",
    //         value: "",
    //       },
    //       // {
    //       //   type: "date",
    //       //   label: "Date de fin",
    //       //   name: "dateFin",
    //       //   // required: true,
    //       //   // value: "2022-11-24",
    //       //   value: "",
    //       // },
    //       // {
    //       //   type: "react-select",
    //       //   label: "Parcelles",
    //       //   placeholder: "Parcelles",
    //       //   name: "parcelles",
    //       //   isMulti: true,
    //       //   // required: true,
    //       //   options: [
    //       //     {
    //       //       id: 1,
    //       //       label: "parcelle 1",
    //       //     },
    //       //     {
    //       //       id: 2,
    //       //       label: "parcelle 2",
    //       //     },
    //       //   ],
    //       //   value: [],
    //       // },
    //       // {
    //       //   type: "react-select",
    //       //   label: "Groupes de parcelles",
    //       //   placeholder: "Groupes de parcelles",
    //       //   name: "groupesParcelles",
    //       //   isMulti: true,
    //       //   options: [],
    //       //   value: [],
    //       //   colors: false,
    //       // },
    //       // {
    //       //   type: "react-select",
    //       //   label: "Types d'intervention",
    //       //   placeholder: "Types d'intervention",
    //       //   name: "typesIntervention",
    //       //   isMulti: true,
    //       //   options: [
    //       //     {
    //       //       id: 76,
    //       //       label: "Semis",
    //       //     },
    //       //     {
    //       //       id: 60,
    //       //       label: "Traitement phytosanitaire",
    //       //     },
    //       //     {
    //       //       id: 61,
    //       //       label: "Fertilisation / Amendement",
    //       //     },
    //       //     {
    //       //       id: 82,
    //       //       label: "Récolte",
    //       //     },
    //       //   ],
    //       //   value: [
    //       //     {
    //       //       id: 76,
    //       //       label: "Semis",
    //       //     },
    //       //   ],
    //       // },
    //       // {
    //       //   type: "react-select",
    //       //   label: "Statuts des interventions",
    //       //   placeholder: "Statuts des interventions",
    //       //   name: "statutsIntervention",
    //       //   options: [],
    //       //   // required: true,
    //       //   value: [
    //       //     {
    //       //       value: 3,
    //       //       label: "Effectué",
    //       //     },
    //       //   ],
    //       // },
    //       // {
    //       //   type: "radio",
    //       //   label: "cout",
    //       //   name: "cout",
    //       //   options: [
    //       //     {
    //       //       id: 1,
    //       //       label: "Avec coûts",
    //       //     },
    //       //     {
    //       //       id: 2,
    //       //       label: "Sans coûts",
    //       //     },
    //       //   ],
    //       //   value: 1,
    //       // },
    //     ],
    //     tableau: {
    //       config: {
    //         columnDefs: [],
    //         rowData: [],
    //         tableauFiltrable: true,
    //       },
    //     },
    //     boutons: [
    //       {
    //         titre: "Générer le tableau",
    //         requete: "tableauFiltrable",
    //       },
    //     ],
    //   },
    // };

    // const data = {
    //   type: "onglets",
    //   config: [
    //     {
    //       titre: "Onglet 1",
    //       enfants: [tableauFiltrable],
    //     },
    //     // {
    //     //   titre: "Onglet 2",
    //     //   enfants: [tableauFiltrable],
    //     // },
    //   ],
    // };

    this.setState({ data: data, isDataLoaded: true });
  }

  render() {
    return this.state.isDataLoaded ? (
      <Generique data={this.state.data} />
    ) : null;
  }
}

export default WithContext(Parametrable);
